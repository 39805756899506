export const gipCodePrefixed = (code?: string | null) => {
  if (!code) {
    return code;
  }

  // should not be happening, but just in case
  if (code.startsWith('GIP')) {
    return code;
  }

  return `GIP-${code}`;
};
