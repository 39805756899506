import { date, InferType, number, object } from 'yup';

export const EbpCaseAppointmentsSchema = object().shape({
  // completed by expert (nurse) or professional
  completed: number().required(),
  completedByProfessionals: number().required(),
  completedByExperts: number().required(),
  latestCompletedByExpertDate: date().nullable().default(undefined),
});

export type EbpCaseAppointments = InferType<typeof EbpCaseAppointmentsSchema>;
