import { date, InferType, mixed, number, object, string } from 'yup';
import { EbpCaseSupportAuthorizationActionType } from '../../enums';

export const EbpCaseSupportAuthorizationActionSchema = object().shape({
  type: mixed<EbpCaseSupportAuthorizationActionType>()
    .oneOf(Object.values(EbpCaseSupportAuthorizationActionType))
    .required(),
  by: string().nullable(),
  endDate: object().shape({ old: date(), new: date().required() }).optional(),
  maxAppointments: object()
    .shape({
      old: number().required(),
      new: number().required(),
    })
    .optional(),
  notes: string(),
  link: string(),
  createdAt: date().required(),
});

export type EbpCaseSupportAuthorizationAction = InferType<
  typeof EbpCaseSupportAuthorizationActionSchema
>;
