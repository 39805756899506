import { AlertError, FormLabel, Spinner } from '@percihealth/react';
import { useSubscribeSupportAuthorizationHistory } from '../../../../../api';
import {
  dateTimeToLondonDateTimeString,
  dateToLondonNumericDateString,
  EbpCaseSupportAuthorizationAction,
  EbpCaseSupportAuthorizationActionType,
} from '@packages/core-shared';
import styles from './SupportAuthorizationActionHistory.module.css';

export const SupportAuthorizationActionHistory = ({
  caseId,
}: {
  caseId: string;
}) => {
  const actionsLoader = useSubscribeSupportAuthorizationHistory(caseId);

  const displayAction = (action: EbpCaseSupportAuthorizationAction) => {
    let actionChange = '';
    switch (action.type) {
      case EbpCaseSupportAuthorizationActionType.approved:
        actionChange = 'marked as approved';
        break;
      case EbpCaseSupportAuthorizationActionType.rejected:
        actionChange = 'marked as rejected';
        break;
      case EbpCaseSupportAuthorizationActionType.requested:
        actionChange = 'requested pre-authorisation';
        break;
      case EbpCaseSupportAuthorizationActionType.not_required:
        actionChange = 'marked as not required';
        break;
      case EbpCaseSupportAuthorizationActionType.task_board:
        actionChange = 'added Monday.com';
        break;
    }

    const endDateChange = action.endDate ? (
      <>
        , changed end of Perci support date from{' '}
        <b>{dateToLondonNumericDateString(action.endDate.old)}</b> to{' '}
        <b>{dateToLondonNumericDateString(action.endDate.new)}</b>
      </>
    ) : (
      <></>
    );
    const maxAppointmentsChange = action.maxAppointments ? (
      <>
        , changed max session from <b>{action.maxAppointments.old}</b> to{' '}
        <b>{action.maxAppointments.new}</b>
      </>
    ) : (
      <></>
    );

    const notes = action.notes ? <>, notes: {action.notes}</> : <></>;

    const link = action.link ? (
      <>
        {' '}
        <a href={action.link} target="_blank">
          task
        </a>
      </>
    ) : (
      <></>
    );

    return (
      <div>
        {action.by} <b>{actionChange}</b>
        {endDateChange}
        {maxAppointmentsChange}
        {notes}
        {link}
        {' - '}
        <span className={styles.timestamp}>
          {dateTimeToLondonDateTimeString(action.createdAt)}
        </span>
      </div>
    );
  };

  if (actionsLoader.loading) {
    return <Spinner />;
  }

  if (actionsLoader.errorMessage) {
    return <AlertError>{actionsLoader.errorMessage}</AlertError>;
  }

  return (
    <div>
      <FormLabel bold>Authorisation Log</FormLabel>
      {actionsLoader.records.map(
        (action: EbpCaseSupportAuthorizationAction) => (
          <div key={action.createdAt.toISOString()}>
            {displayAction(action)}
          </div>
        ),
      )}
    </div>
  );
};
