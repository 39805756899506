import {
  ReferralDefault,
  ReferralDefaultSchema,
  ReferralHealix,
  ReferralHealixSchema,
  ReferralGeneric,
  ReferralGenericSchema,
  ReferralLandg,
  ReferralLandgSchema,
} from '.';
import { InferType, lazy, mixed } from 'yup';
import { ReferralType } from '../enums';

export const ReferralDataSchema = lazy((value) => {
  const { type } = value as { type: ReferralType };
  switch (type) {
    case ReferralType.default:
      return ReferralDefaultSchema;
    case ReferralType.healix:
      return ReferralHealixSchema;
    case ReferralType.landg:
      return ReferralLandgSchema;
    case ReferralType.generic:
      return ReferralGenericSchema;
    default:
      return mixed(); // any type
  }
});

export type ReferralData = InferType<typeof ReferralDataSchema>;

export type Referral =
  | ReferralDefault
  | ReferralHealix
  | ReferralLandg
  | ReferralGeneric;
