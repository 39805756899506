import { bool, date, InferType, number, object } from 'yup';
import { EbpCaseSupportAuthorizationSchema } from './EbpCaseSupportAuthorizationSchema';

// Miro board: https://miro.com/app/board/uXjVMyBaM2M=
export const EbpCaseSupportSchema = object().shape({
  // end of Perci support date, for Level 1 and Level 2
  endDate: date(),

  // max allowed session
  // for Level 2: all completed appointments by professionals, not the assigned nurse (expert)
  // and Level 3: all completed appointments by professionals or nurses
  maxAppointments: number(),

  // To nurses in Slack:
  // 2 weeks before the end date or
  // ending professional sessions for Level 2 support
  endNotificationOne: bool(),

  // To nurses in Slack:
  // 1 week has passed since the last Nurse (expert) appointment,
  // and not future bookings exist
  noFutureBookingNotification: bool(),

  // requests to extend
  authorization: EbpCaseSupportAuthorizationSchema.optional(),
});

export type EbpCaseSupport = InferType<typeof EbpCaseSupportSchema>;
