import {
  AlertError,
  Button,
  InputText,
  Modal,
  RadioButton,
} from '@percihealth/react';
import { useEffect, useMemo, useState } from 'react';
import { CasesSupportAuthorizationApiRepository } from '../../../../../api';
import { useFirebase } from '../../../../../context';
import { dateToLondonNumericDateString } from '@packages/core-shared';
import { useMaxAppointments } from './useMaxAppointments';
import { useSupportEndDate } from './useSupportEndDate';
import styles from './SupportAuthorizationApprovedLevelTwoModal.module.css';

interface Props {
  caseId: string;
  endDate?: Date | null;
  maxAppointments?: number;
  open: boolean;
  onClose: () => void;
}

export const SupportAuthorizationApprovedLevelTwoModal = ({
  caseId,
  endDate,
  maxAppointments,
  open,
  onClose,
}: Props) => {
  const { auth } = useFirebase();
  const endOfSupportApiRepository = useMemo(
    () => new CasesSupportAuthorizationApiRepository(auth),
    [auth],
  );
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [selectedMonths, setSelectedMonths] = useState(0);
  const [selectedSessions, setSelectedSessions] = useState(0);
  const MAX_SESSIONS_TO_ADD = 24;

  const newEndDate = useSupportEndDate(selectedMonths, endDate);

  const { oldMaxAppointments, newMaxAppointments } = useMaxAppointments(
    selectedSessions,
    maxAppointments,
  );

  useEffect(() => {
    setErrorMessage(null);
    setSubmitting(false);
    setSelectedMonths(0);
    setSelectedSessions(0);
  }, [caseId, open]);

  const approve = async () => {
    if (!newEndDate) {
      return;
    }

    setSubmitting(true);
    setErrorMessage(null);
    try {
      await endOfSupportApiRepository.authorizationLevelTwo(
        caseId,
        newEndDate,
        newMaxAppointments,
      );
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} center>
      <h3>Approved Level 2</h3>
      {!endDate && (
        <div className="full-width">
          ⚠️ Perci end of support date is not calculated yet
        </div>
      )}
      {endDate && (
        <div>
          <p>
            Have we received additional sessions or months for <b>{caseId}</b>?
          </p>
          <div className={styles['selection-container']}>
            <div>
              Extend nursing coverage with:
              <RadioButton
                name="extend_level_two"
                id="extend_level_two.0"
                checked={selectedMonths === 0}
                value={1}
                text="0 months"
                onChange={() => setSelectedMonths(0)}
              />
              <RadioButton
                name="extend_level_two"
                id="extend_level_two.1"
                checked={selectedMonths === 1}
                value={1}
                text="1 month"
                onChange={() => setSelectedMonths(1)}
              />
              <RadioButton
                id="extend_level_two.2"
                name="extend_level_two"
                checked={selectedMonths === 2}
                value={2}
                text="2 months"
                onChange={() => setSelectedMonths(2)}
              />
              <RadioButton
                id="extend_level_two.3"
                name="extend_level_two"
                checked={selectedMonths === 3}
                value={3}
                text="3 months"
                onChange={() => setSelectedMonths(3)}
              />
            </div>
            <div>
              Add professional sessions:
              <InputText
                value={selectedSessions.toString()}
                max={2}
                onChange={(e) => {
                  // remove non-numeric characters
                  e.target.value = e.target.value.replace(/\D/g, '');
                  const num = parseInt(e.target.value);

                  if (!isNaN(num)) {
                    if (num <= MAX_SESSIONS_TO_ADD) {
                      setSelectedSessions(num);
                    }
                  } else {
                    setSelectedSessions(0);
                  }
                }}
                suffix="sessions"
              />
              <div>
                <small>
                  maximum increase in {MAX_SESSIONS_TO_ADD} sessions per request
                </small>
              </div>
            </div>
          </div>
          <br />
          {selectedMonths > 0 && (
            <div>
              The end of date will be extended till{' '}
              {dateToLondonNumericDateString(newEndDate)}
            </div>
          )}
          {selectedSessions > 0 && (
            <div>
              Sessions limit will be extended from {oldMaxAppointments} to{' '}
              {newMaxAppointments}
            </div>
          )}
          <br />
          <br />
          {errorMessage && <AlertError>{errorMessage}</AlertError>}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={onClose} disabled={submitting}>
              Abort
            </Button>

            <Button
              level="secondary"
              submitting={submitting}
              onClick={async () => {
                await approve();
              }}
              disabled={selectedMonths === 0 && selectedSessions === 0}
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
