import {
  AlertError,
  Button,
  DropdownSearch,
  InputText,
} from '@percihealth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './QuickCaseOpen.module.css';
import { useFirebase } from '../../../../../context';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

enum OpenBy {
  gip_code = 'gip_code',
  short_id = 'short_id',
}

export const QuickCaseOpen = () => {
  const { firestore } = useFirebase();
  const navigate = useNavigate();

  const [openBy, setOpenBy] = useState<OpenBy>(
    (localStorage.getItem('case_list_open_by') as OpenBy | undefined) ??
      OpenBy.short_id,
  );

  const [value, setValue] = useState('');

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setErrorMessage(null);
  }, [value, openBy]);

  const options = useMemo(
    () => [
      {
        value: OpenBy.gip_code,
        name: 'GIP code',
      },
      {
        value: OpenBy.short_id,
        name: 'Short ID',
      },
    ],
    [],
  );

  const goToCase = useCallback(
    async (type: OpenBy, v: string) => {
      if (!v) {
        return;
      }

      if (type === OpenBy.gip_code) {
        const refQ = query(
          collection(firestore, 'referrals'),
          where('gip.code', '==', v),
        );
        const refSnapshot = await getDocs(refQ);

        if (refSnapshot.empty) {
          setErrorMessage('Referral not found by GIP code');
          return;
        }

        const referralId = refSnapshot.docs[0].id;

        const caseQ = query(
          collection(firestore, 'cases'),
          where('referralId', '==', referralId),
        );
        const caseSnapshot = await getDocs(caseQ);

        if (caseSnapshot.empty) {
          setErrorMessage(`Case not found for referral id: ${referralId}`);
          return;
        }

        navigate(`/cases/${caseSnapshot.docs[0].id}`);
        return;
      } else if (type === OpenBy.short_id) {
        const caseQ = query(
          collection(firestore, 'cases'),
          where('patient.shortId', '==', v),
        );
        const caseSnapshot = await getDocs(caseQ);

        if (caseSnapshot.empty) {
          setErrorMessage(`Case not found for short id: ${v}`);
          return;
        }

        navigate(`/cases/${caseSnapshot.docs[0].id}`);
        return;
      }
    },
    [firestore],
  );

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <DropdownSearch
          onChange={(value) => {
            setOpenBy(value as OpenBy);
            localStorage.setItem('case_list_open_by', value as string);
          }}
          options={options}
          defaultValue={openBy}
          value={openBy}
        />
      </div>
      <InputText
        className={styles.input}
        prefix={openBy === OpenBy.gip_code ? 'GIP-' : undefined}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            goToCase(openBy, value);
          }
        }}
      />
      <Button className={styles.btn} onClick={() => goToCase(openBy, value)}>
        Go
      </Button>
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
    </div>
  );
};
