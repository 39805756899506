import { useFirebase } from '@packages/web-shared';
import { AlertError, Button, Modal, RadioButton } from '@percihealth/react';
import { useEffect, useMemo, useState } from 'react';
import { CasesSupportAuthorizationApiRepository } from '../../../../../api';
import { useMaxAppointments } from './useMaxAppointments';

interface Props {
  caseId: string;
  maxAppointments?: number;
  open: boolean;
  onClose: () => void;
}

export const SupportAuthorizationApprovedLevelThreeModal = ({
  caseId,
  maxAppointments,
  open,
  onClose,
}: Props) => {
  const { auth } = useFirebase();
  const endOfSupportApiRepository = useMemo(
    () => new CasesSupportAuthorizationApiRepository(auth),
    [auth],
  );

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [selectedSessions, setSelectedSessions] = useState(0);

  const { oldMaxAppointments, newMaxAppointments } = useMaxAppointments(
    selectedSessions,
    maxAppointments,
  );

  useEffect(() => {
    setErrorMessage(null);
    setSubmitting(false);
    setSelectedSessions(0);
  }, [caseId, open]);

  const approve = async () => {
    setSubmitting(true);
    setErrorMessage(null);
    try {
      await endOfSupportApiRepository.authorizationLevelThree(
        caseId,
        newMaxAppointments,
      );
      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} center>
      <h3>Approved Level 3</h3>
      <p>
        Have we received additional sessions for <b>{caseId}</b>?
      </p>
      <div>
        Add professional or nurse sessions:
        <RadioButton
          name="extend_level_session_two"
          id="extend_level_session_two.0"
          checked={selectedSessions === 0}
          value={0}
          text="0 sessions"
          onChange={() => setSelectedSessions(0)}
        />
        <RadioButton
          name="extend_level_session_two"
          id="extend_level_session_two.1"
          checked={selectedSessions === 1}
          value={1}
          text="1 session"
          onChange={() => setSelectedSessions(1)}
        />
        <RadioButton
          id="extend_level_session_two.2"
          name="extend_level_session_two"
          checked={selectedSessions === 2}
          value={2}
          text="2 sessions"
          onChange={() => setSelectedSessions(2)}
        />
        <RadioButton
          id="extend_level_session_two.3"
          name="extend_level_session_two"
          checked={selectedSessions === 3}
          value={3}
          text="3 sessions"
          onChange={() => setSelectedSessions(3)}
        />
        <RadioButton
          id="extend_level_session_two.4"
          name="extend_level_session_two"
          checked={selectedSessions === 4}
          value={4}
          text="4 sessions"
          onChange={() => setSelectedSessions(4)}
        />
        <RadioButton
          id="extend_level_session_two.5"
          name="extend_level_session_two"
          checked={selectedSessions === 5}
          value={5}
          text="5 sessions"
          onChange={() => setSelectedSessions(5)}
        />
      </div>
      <br />
      {selectedSessions > 0 && (
        <div>
          Sessions limit will be extended from {oldMaxAppointments} to{' '}
          {newMaxAppointments}
        </div>
      )}
      <br />
      Continue?
      <br />
      <br />
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onClose} disabled={submitting}>
          Abort
        </Button>

        <Button
          level="secondary"
          submitting={submitting}
          disabled={selectedSessions === 0}
          onClick={async () => {
            await approve();
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
