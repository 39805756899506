import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import debounce from 'lodash/debounce';
import styles from './CreatePcpPage.module.scss';
import DocumentResourceList from '../components/DocumentResourceList';
import PcpServices, {
  populatePatientServicesWithAppointments,
} from './PcpServices';
import {
  EbpCase,
  IamCancerOptionsWithEmptyValue,
  PcpData,
  PcpDataSchema,
  cancerStatusToString,
  CareDocumentType,
  displayEbpDocumentType,
  Referral,
  ReferralType,
  ReferralLandg,
  OrganizationId,
  getFullname,
  earliestDayTime,
  deepPartialOrNullable,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikDatepicker,
  FormikDropdownSearch,
  FormikInputText,
  FormikTextArea,
  Spinner,
} from '@percihealth/react';
import { useParams } from 'react-router-dom';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { CareDocumentsApiRepository } from '@packages/web-shared/api/care-documents';
import { getDownloadURL, ref } from 'firebase/storage';
import { PdfGeneratedModal } from '../components/PdfGeneratedModal';
import {
  useLoadPatientAppointmentsByService,
  useLoadServices,
} from '@packages/web-shared/api';
import { PcpFormGenerateByAiButton } from './PcpFormGenerateByAiButton';
import { useFirebase } from '@packages/web-shared';
import { useLoadPcp, useLoadPcpAutosaved } from './useLoadPcp';

export default function CreatePcpPage() {
  const params = useParams();
  const caseId = params?.caseId ?? null;

  if (!caseId) {
    return <div>Case ID (email) should be specified</div>;
  }

  const { storage, auth } = useFirebase();

  const [openPdfModal, setOpenPdfModal] = useState(false);
  useEffect(() => {
    setOpenPdfModal(false);
  }, [caseId]);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useGetFirestoreDocument<EbpCase>('cases', caseId);

  const pcpLoader = useLoadPcp(ebpCase?.documents?.pcp?.id);
  const pcpAutosavedLoader = useLoadPcpAutosaved(caseId);

  const { record: referral } = useGetFirestoreDocument<Referral>(
    'referrals',
    ebpCase?.referralId,
  );
  const gipCode = useMemo(() => {
    if (referral?.type !== ReferralType.landg) {
      return '';
    }

    return (referral as ReferralLandg)?.gip?.code ?? '';
  }, [referral]);

  const servicesLoader = useLoadServices();

  // prevent inifinite loop re-render
  const recommendedServices = useMemo(() => [], []);
  const appointmentsByServiceLoader = useLoadPatientAppointmentsByService({
    patientEmail: ebpCase?.patient?.email,
    recommendedServices,
  });

  const [createResponseError, setCreateResponseError] = useState<string | null>(
    null,
  );

  const [autosaveError, setAutosaveError] = useState<string | null>(null);
  useEffect(() => {
    setAutosaveError(null);
  }, [caseId]);

  const autoSave = async (values: any) => {
    console.log('autosave');
    setAutosaveError(null);
    try {
      values.caseId = caseId;
      await docsApiRepository.autosavePcpForm(values);
    } catch (error: unknown) {
      console.error(error);
      setAutosaveError('Failed to autosave');
    }
  };
  const debouncedAutosave = useRef(debounce(autoSave, 2000));

  const handleOnSubmit = async (
    values: PcpData,
    actions: FormikHelpers<PcpData>,
  ) => {
    try {
      actions.setSubmitting(true);
      setCreateResponseError(null);
      const { storageRef } = await docsApiRepository.createPcp({
        ...values,
        caseId,
      });

      const previewUrl = await getDownloadURL(ref(storage, storageRef));

      setOpenPdfModal(true);

      // Open the file in a new tab, so it is not automatically downloaded to the local storage
      window.open(previewUrl, '_blank');
    } catch (error: unknown) {
      let errorMsg = '';

      if (error instanceof Error) {
        errorMsg = error.message;
      } else {
        errorMsg = JSON.stringify(error);
      }

      console.error(errorMsg);
      setCreateResponseError(errorMsg);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formikDependencies = [
    pcpLoader.record,
    pcpAutosavedLoader.record,
    ebpCase,
    gipCode,
    appointmentsByServiceLoader.records,
    servicesLoader.services,
  ];

  const initialValues: PcpData = useMemo(() => {
    let docData = pcpAutosavedLoader.record ?? pcpLoader.record;
    if (docData) {
      // Deep clone
      try {
        // schema cast is required to have Date type instead of strings
        docData = deepPartialOrNullable(PcpDataSchema).cast(
          JSON.parse(JSON.stringify(docData)),
        ) as PcpData;
      } catch {
        // intentionally left blank
      }
    }
    // Show previous PCP info if it was specified previously
    const result = docData ?? {
      caseId,
      type: CareDocumentType.PersonalisedCarePlan,
      patient: {
        fullName: ebpCase?.patient ? getFullname(ebpCase.patient) : '',
        dob: ebpCase?.patient?.dateOfBirth ?? new Date(),
        iam: ebpCase?.patient?.cancerStatus
          ? cancerStatusToString(ebpCase?.patient?.cancerStatus)
          : IamCancerOptionsWithEmptyValue[0],
      },
      gip: {
        code: gipCode,
      },
      notes: defaultNotes,
      recoveryRoadmap: '',
      dateOfConsultation: earliestDayTime(new Date()),
      patientServices: [],
      personalGoals: [],
      resources: [],
    };

    result.patientServices = populatePatientServicesWithAppointments(
      result.patientServices,
      servicesLoader.services,
      appointmentsByServiceLoader.records,
    );

    return result;
  }, [...formikDependencies]);

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: PcpDataSchema.isValidSync(initialValues),
    initialValues: initialValues,
    validationSchema: PcpDataSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    formik.resetForm();
  }, [...formikDependencies]);

  useEffect(() => {
    if (formik?.dirty) {
      debouncedAutosave.current(formik.values);
    }
  }, [formik?.values, formik?.dirty]);

  if (caseLoading || pcpLoader.loading) {
    return <Spinner />;
  }

  if (caseLoadingErrorMessage || !ebpCase) {
    return (
      <AlertError>{caseLoadingErrorMessage ?? 'Not found case'}</AlertError>
    );
  }

  if (pcpLoader.errorMessage) {
    return <AlertError>{pcpLoader.errorMessage}</AlertError>;
  }

  if (pcpAutosavedLoader.errorMessage) {
    return <AlertError>{pcpAutosavedLoader.errorMessage}</AlertError>;
  }

  return (
    <FormikProvider value={formik}>
      <div>
        {autosaveError && <AlertError>{autosaveError}</AlertError>}
        {process.env.REACT_APP_FEATURE_ENABLE_FILL_BY_AI_BUTTON && (
          <PcpFormGenerateByAiButton
            caseId={ebpCase.id}
            currentForm={formik.values}
            onChange={(data) => {
              if (data.patient?.iam) {
                formik.setFieldValue('patient.iam', data.patient?.iam, true);
              }
              formik.setFieldValue('notes', data.notes ?? '', true);
              formik.setFieldValue(
                'dateOfConsultation',
                data.dateOfConsultation ?? null,
                true,
              );
              const pss = populatePatientServicesWithAppointments(
                data.patientServices ?? [],
                servicesLoader.services,
                appointmentsByServiceLoader.records,
              );
              data.patientServices = pss;
              formik.setFieldValue('patientServices', pss, true);

              // save AI form to the backend to track manual changes
              // call asynchronously, no await, does not matter if fails
              docsApiRepository.saveAiPcpForm(caseId, data);
            }}
          />
        )}
        <Form onSubmit={formik.handleSubmit}>
          <FormFieldset title="Patient's details">
            <FormikInputText
              id="patient.fullName"
              name="patient.fullName"
              required
              label="Patient full name"
              value={formik.values.patient.fullName}
            />
            <FormikDatepicker
              id="patient.dob"
              name="patient.dob"
              required
              label="Patient DOB"
              value={formik.values.patient.dob}
            />
            {ebpCase.organization.id === OrganizationId.landg && (
              <FormikInputText
                id="gip.code"
                name="gip.code"
                required
                disabled={!!gipCode}
                label="Group income protection code"
                prefix="GIP-"
                value={formik.values.gip?.code ?? ''}
              />
            )}
          </FormFieldset>
          <FormFieldset title="Manual">
            <FormikDatepicker
              id="dateOfConsultation"
              name="dateOfConsultation"
              required
              label="Date of consultation"
              value={formik.values.dateOfConsultation}
            />
            <FormikDropdownSearch
              name="patient.iam"
              label="Patient is:"
              required
              multiple={false}
              value={formik.values.patient.iam ?? ''}
              options={IamCancerOptionsWithEmptyValue.map((o) => ({
                value: o,
                name: o,
              }))}
            />
            <FormikTextArea
              id="notes"
              name="notes"
              required
              className="full-width"
              label="Notes"
              value={formik.values.notes}
              rows={12}
              description="Here you'll find notes from your first consultation, taken by your nurse
                specialist during your call."
            />
          </FormFieldset>
          <FormFieldset title="Recommended Perci Health support types">
            {servicesLoader.servicesError && (
              <AlertError>{servicesLoader.servicesError}</AlertError>
            )}
            {servicesLoader.servicesLoading && <Spinner />}
            {!servicesLoader.servicesLoading && (
              <PcpServices
                values={formik?.values?.patientServices ?? []}
                services={servicesLoader.services}
                onChange={(pss) =>
                  formik.setFieldValue('patientServices', pss, true)
                }
              />
            )}
          </FormFieldset>
          <FormFieldset title="Resource list">
            <DocumentResourceList
              resources={formik.values.resources ?? []}
              onChange={(updated) => formik.setFieldValue('resources', updated)}
            />
          </FormFieldset>
          <hr />
          <div className={styles.full}>
            <Button
              type="submit"
              className={styles.submit}
              fullWidth
              disabled={!formik.isValid}
              submitting={formik.isSubmitting}
            >
              Create{' '}
              {displayEbpDocumentType(CareDocumentType.PersonalisedCarePlan)}
            </Button>
          </div>
          {createResponseError && (
            <AlertError>{createResponseError}</AlertError>
          )}
        </Form>
      </div>
      <PdfGeneratedModal
        caseId={caseId}
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
      />
    </FormikProvider>
  );
}

const defaultNotes = `Diagnosis and Treatment


Current medications


Other relevant medical history


Psychological


Physical


Social


Current support (treatment team, GP, charity)


Work

`;
